import JhaFetch from '../services/jha-fetch.js';
import PasswordApi from '../services/auth/password-api.js';
import LoginApi from '../services/auth/login-api.js';
import JhaSessionStorage from '../session-storage/session-storage.js';
const _sessionStorage = new JhaSessionStorage();
class PasswordController {
  static setPassword(body, logoutBeforeReset) {
    const setPasswordFetch = () => JhaFetch.fetch({
      api: PasswordApi.sentryPasswordSetFactory(),
      body,
      method: 'POST',
    }).catch(error => Promise.reject(PasswordController.processSetPasswordError(error)));
    if (logoutBeforeReset) {
      return JhaFetch.fetch({
        api: LoginApi.sentryLogin(),
        method: 'DELETE',
      }).then(() => {
        _sessionStorage.removeItem('BANNO_USER');
        _sessionStorage.removeItem('BANNO_INSTITUTION_LIST');
        return setPasswordFetch();
      })
          .catch(setPasswordFetch);
    }
    return setPasswordFetch();
  }
  static validatePassword(body) {
    return JhaFetch.fetch({
      api: PasswordApi.sentryPasswordCheckFactory(),
      body,
      method: 'PUT',
    }).then(data => PasswordController.errorsFilter(data)).catch(error => Promise.reject(error));
  }
  static processSetPasswordError(error) {
    if (error['data']) {
      error['data'] = PasswordController.errorsFilter(error['data']);
    }
    return error;
  }
  static errorsFilter(errors) {
    let pluralized = '';
    const errorsArray = [];
    if (errors['allRulesPassed'] === false) {
      if (errors['alphaSeqRulePassed'] === false) {
        errorsArray.push('You used a sequence of letters that is not very secure. Avoid passwords like "abcd".');
      }
      if (errors['confirmationMatchRulePassed'] === false) {
        errorsArray.push('The two passwords you typed don\'t match.');
      }
      if (errors['digitRulePassed'] === false) {
        pluralized = errors['minDigitsAllowed'] === 1 ? 'number is' : 'numbers are';
        errorsArray.push(`At least ${errors['minDigitsAllowed']} ${pluralized} required.`);
      }
      if (errors['lengthRulePassed'] === false) {
        errorsArray.push(`Your password should be between ${errors['minLengthAllowed']} and ${errors['maxLengthAllowed']} characters in length.`);
      }
      if (errors['lowercaseRulePassed'] === false) {
        pluralized = errors['minLowercaseAllowed'] === 1 ? 'lowercase letter' : 'lowercase letters';
        errorsArray.push(`Your password should have at least ${errors['minLowercaseAllowed']} ${pluralized}.`);
      }
      if (errors['nonAlphaRulePassed'] === false) {
        pluralized = errors['minNonAlphasAllowed'] === 1 ? 'special character' : 'special characters';
        errorsArray.push(`Your password should have at least ${errors['minNonAlphasAllowed']} ${pluralized}.`);
      }
      if (errors['numSeqRulePassed'] === false) {
        errorsArray.push('You used a sequence of numbers that is not very secure. Avoid passwords like "1234".');
      }
      if (errors['qwertySeqRulePassed'] === false) {
        errorsArray.push('Your password can\'t contain a sequence of key presses like "qwerty".');
      }
      if (errors['repeatCharRulePassed'] === false) {
        errorsArray.push(`The maximum number of characters that can be repeated is ${errors['maxRepeatCharsAllowed']}.`);
      }
      if (errors['uppercaseRulePassed'] === false) {
        pluralized = errors['minUppercaseAllowed'] === 1 ? 'uppercase letter' : 'uppercase letters';
        errorsArray.push(`Your password should have at least ${errors['minUppercaseAllowed']} ${pluralized}.`);
      }
      if (errors['whitespaceRulePassed'] === false) {
        errorsArray.push('Spaces are not allowed.');
      }
    }
    return errorsArray;
  }
}
export default PasswordController;
