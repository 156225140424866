import {PolymerElement, html} from '@polymer/polymer/polymer-element.js';
import './jha-set-password.js';
import UserAccessController from '@banno/platform-ux-shared/controllers/user-access-controller.js';
import {routingMixin as RouterMixin} from '@jack-henry/web-component-router';
import router from '@banno/platform-ux-shared/services/router-jh-wcr.js';
/**
 * @constructor
 * @polymer
 * @extends {PolymerElement}
 */
const SetPasswordContainerBase = RouterMixin(PolymerElement);
/** @polymer */
class SetPasswordContainerElement extends SetPasswordContainerBase {
  static get is() {
    return 'set-password-container';
  }

  async routeEnter(currentNode, nextNodeIfExists, routeId, context, next) {
    await super.routeEnter(currentNode, nextNodeIfExists, routeId, context, next);
    if (context.query.get('id')) {
      this.passwordResetId = context.query.get('id');
    }
    UserAccessController.isLoggedIn().catch(() => {
      if (!this.passwordResetId) {
        this._fireToast('toastSetPasswordMustLogin');
        router.go('/a');
      }
    });
  }

  static get template() {
    return html`
    <style>
      :host {
        display: block;
        width: 90%;
        min-height: 0;
        max-height: none;
        max-width: 400px;
        border-radius: 2px;
        box-shadow: 0 3px 12px 0 rgba(37,49,62,.24),0 3px 24px rgba(37,49,62,.12);
        border: none;
        margin: auto;
        padding: 0;
        background: white;
      }
    </style>
    <jha-set-password></jha-set-password>
  `;
  }
}
customElements.define(SetPasswordContainerElement.is, SetPasswordContainerElement);
export default SetPasswordContainerElement;
