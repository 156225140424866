import {PolymerElement, html} from '@polymer/polymer/polymer-element.js';
import {DomIf} from '@polymer/polymer/lib/elements/dom-if.js';
import {DomRepeat} from '@polymer/polymer/lib/elements/dom-repeat.js';
import '@banno/platform-ux-shared/components/polymer3/jha/forms/jha-form-floating-group.js';
import '@banno/platform-ux-shared/components/polymer3/jha/buttons/jha-button.js';
import PasswordController from '@banno/platform-ux-shared/controllers/password-controller.js';

/**
 * @polymer
 * @extends {PolymerElement}
 */
class JhaSetPasswordElement extends PolymerElement {
  static get is() {
    return 'jha-set-password';
  }
  static get properties() {
    return {
      buttonLoading: {
        type: Boolean,
        value: false,
      },
      passwordResetId: {
        type: String,
        value: ''
      },
      passwordViolations: {
        type: Array,
        value: () => []
      },
      oldPassword: String,
      newPassword: String,
      confirmPassword: String,
    };
  }

  constructor() {
    super();
    this.boundKeyHandler_ = this._keyHandler.bind(this);
  }

  connectedCallback() {
    super.connectedCallback();
    this.addEventListener('keydown', this.boundKeyHandler_);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.removeEventListener('keydown', this.boundKeyHandler_);
  }

  _keyHandler(e) {
    const ENTER = 13;
    const pressedKey = e.keyCode;
    if (pressedKey === ENTER) {
      if (this._hasValidInput(
          this.passwordViolations,
          this.passwordResetId,
          this.oldPassword,
          this.newPassword,
          this.confirmPassword)
      ) {
        this._setPassword();
      }
    }
  }

  _computeFormData(passwordResetId, oldPassword, newPassword, confirmPassword) {
    const returnObject = {
      password: newPassword,
      confirm: confirmPassword
    };
    if (passwordResetId) {
      returnObject.id = passwordResetId;
    } else {
      returnObject.oldPassword = oldPassword;
    }
    return returnObject;
  }

  _checkValidation(newPassword, confirmPassword) {
    if (newPassword !== '' && confirmPassword !== '') {
      PasswordController.validatePassword({password: newPassword, confirm: confirmPassword}).then((data) => {
        this.passwordViolations = data;
      });
    }
  }

  _hasValidInput(passwordViolations, passwordResetId, oldPassword, newPassword, confirmPassword) {
    const displayViolations = this._computeDisplayViolations(passwordViolations);
    if (displayViolations) {
      return false;
    }
    if (newPassword === '' || confirmPassword === '') {
      return false;
    }
    if (passwordResetId === '' && oldPassword === '') {
      return false;
    }
    return true;
  }

  _setPassword() {
    const errorStatus400 = 400;
    const errorStatus401 = 401;
    this.buttonLoading = true;
    const formData = this._computeFormData(
        this.passwordResetId, this.oldPassword, this.newPassword, this.confirmPassword);
    PasswordController.setPassword(formData, this.passwordResetId).then((response) => {
      if (!this.passwordResetId) {
        this.oldPassword = '';
        this.newPassword = '';
        this.confirmPassword = '';
        this._fireToast('toastSetPasswordReset');
        this.$['toast-reset'].setAttribute('is-visible', true);
        router.go('/a');
      } else {
        this._fireToast('toastSetPasswordLogin');
        router.go('/a');
      }
      this.buttonLoading = false;
    }).catch((error) => {
      if (error.data) {
        this.passwordViolations = error.data;
      }
      if (!this.passwordResetId) {
        this._fireToast('toastSetPasswordBadOldpassword');
      } else if (error.status === errorStatus401) {
        this._fireToast('toastSetPassword401');
      } else if (error.status === errorStatus400) {
        this._fireToast('toastSetPassword400');
      }
      this.buttonLoading = false;
    });
  }

  _fireToast(toastId) {
    this.dispatchEvent(new CustomEvent('toast', {
      bubbles: true,
      composed: true,
      detail: toastId
    }));
  }

  _computeDisplayViolations(passwordViolations) {
    return passwordViolations.length > 0;
  }

  static get template() {
    return html`
    <style>
      :host {
        display: block;
      }
      header,
      form,
      footer {
        text-align: center;
      }
      header {
        padding: 16px 16px 0;
      }
      h2 {
        color: var(--jha-text-dark);
        margin: 12px 0 24px;
        font-size: 16px;
        font-weight: 600;
      }
      form {
        padding: 16px;
        margin: 0;
      }
      .passwordError {
        max-width: 300px;
        text-align: center;
        color: var(--jha-color-danger);
        font-size: 12px;
        margin: 0 auto;
        -webkit-font-smoothing: antialiased;
      }
      .passwordError ul {
        text-align: left;
      }
      jha-form-floating-group {
        width: 300px;
        margin: 0 auto 16px;
      }
      jha-well {
        color: var(--jha-text-light);
        font-size: 13px;
      }
      jha-well div:first-of-type {
        color: var(--jha-text-dark);
        font-weight: 600;
        font-size: 15px;
      }
      a {
        @apply --base-anchor-styles;
      }
      a:hover {
        @apply --base-anchor-hover-styles;
      }
      a:active {
        @apply --base-anchor-active-styles;
      }
      footer {
        width: 100%;
        border-top: 1px solid var(--jha-border-color);
        padding: 16px;
        box-sizing: border-box;
      }
      .toast-container {
        pointer-events: none;
        position: fixed;
        z-index: 1060;
        left: 0;
        bottom: 0;
        width: 100%;
      }
      @media (min-width: 415px) {
        .toast-item{
          max-width: 288px;
          border-radius: 2px;
          bottom: 16px;
          left: 16px;
          width: auto;
        }
      }
      @media (min-width: 740px) {
        header {
          padding: 32px 32px 0;
        }
        h2 {
          font-size: 18px;
          font-weight: 400;
        }
        form,
        footer {
          padding: 32px;
        }
        .toast-item{
          bottom: 32px;
          left: 32px;
          width: auto;
        }
      }
      @media (max-width: 739px) {
        .toast-container {
            bottom: 40px;
        }
      }
    </style>
    <header>
      <h2>Set your password</h2>
    </header>
    <form>
      <template is="dom-if" if="[[_computeDisplayViolations(passwordViolations)]]">
        <div class="passwordError">
          <p>There are some problems with your password:</p>
          <ul>
            <template is="dom-repeat" items="[[passwordViolations]]">
              <li>[[item]]</li>
            </template>
          </ul>
        </div>
      </template>
      <template is="dom-if" if="[[!passwordResetId]]">
        <jha-form-floating-group>
          <!-- eslint-disable @banno/ux/no-auto-binding -->
          <input id="oldPassword" type="password" value="{{oldPassword::input}}" spellcheck="false" autocomplete="current-password" autocorrect="off" autocapitalize="off">
          <div class="label">Old Password</div>
        </jha-form-floating-group>
      </template>
      <jha-form-floating-group>
        <!-- eslint-disable @banno/ux/no-auto-binding -->
        <input id="newPassword" type="password" value="{{newPassword::input}}" on-input="[[_checkValidation(newPassword, confirmPassword)]]" spellcheck="false" autocomplete="new-password" autocorrect="off" autocapitalize="off">
        <div class="label">New Password</div>
      </jha-form-floating-group>
      <jha-form-floating-group>
        <!-- eslint-disable @banno/ux/no-auto-binding -->
        <input id="confirmPassword" type="password" value="{{confirmPassword::input}}" on-input="[[_checkValidation(newPassword, confirmPassword)]]" spellcheck="false" autocomplete="new-password" autocorrect="off" autocapitalize="off">
        <div class="label">Confirm</div>
      </jha-form-floating-group>
    </form>
    <footer>
      <jha-button type="button" id="submitButton" sync="" tabindex="0" on-click="_setPassword" is-loading="[[buttonLoading]]" disabled$="[[!_hasValidInput(passwordViolations, passwordResetId, oldPassword, newPassword, confirmPassword)]]">
        Set Password
      </jha-button>
    </footer>
  `;
  }
}
customElements.define(JhaSetPasswordElement.is, JhaSetPasswordElement);
export default JhaSetPasswordElement;
