import ApiPaths from "../api-paths.js";
'use strict';
class PasswordApi {
    static sentryPasswordSetFactory() {
        return {
            url: `${ApiPaths.SENTRY_PATH}/passwords`,
            requestMethods: ['POST']
        };
    }
    static sentryPasswordCheckFactory() {
        return {
            url: `${ApiPaths.SENTRY_PATH}/passwords/validate`,
            requestMethods: ['PUT']
        };
    }
}
export default PasswordApi;
